import React from "react"
import Layout from "../components/Layout"
import s from "../styles/homepage.module.scss"
import Button from "../components/Button"

export default () => <Layout>
	<header className={s.header}>
		<div className={s.container}>
			<h1>Private crowdfunding, <br/> done right!</h1>
		</div>
	</header>

	<div className={s.container}>
		<div className={s.boxesContainer}>
			<div className={s.box}>
				<i className={`fas fa-user-shield ${s.boxIcon} ${s.boxIcon1}`}></i>
				<h2 className={s.boxTitle}>Private</h2>
				<p className={s.boxText}>We understand that not all crowdfunding campaigns can be public. Ours are private by default.</p>
			</div>
			<div className={s.box}>
				<i className={`fas fa-lock ${s.boxIcon} ${s.boxIcon2}`}></i>
				<h2 className={s.boxTitle}>Secure</h2>
				<p className={s.boxText}>We use technology of leading companies to offer the most secure solution.</p>
			</div>
			<div className={s.box}>
				<i className={`fas fa-dollar-sign ${s.boxIcon} ${s.boxIcon3}`}></i>
				<h2 className={s.boxTitle}>Affordable</h2>
				<p className={s.boxText}>We do not have extra charge other then the normal credit card charges (2.9% + 0.30$ per transaction).</p>
			</div>
		</div>
	</div>

	<main  className={s.main}>
		<div className={s.container}>
			<section className={s.mainSection}>
				<div>
					<i className={`fas fa-user-shield ${s.mainIcon} ${s.mainIcon1}`}></i>
				</div>
				<div>
					<h2 className={s.mainTitle}>Private</h2>
					<p className={s.mainText}>Sometimes crowdfunding needs to be private. This is like GoFundMe but the campaign remains private. You have full control on who can see the campaign details. The pages are also hidden from search engines.</p>
				</div>
			</section>
			<section className={s.mainSection}>
				<div>
					<h2 className={s.mainTitle}>Secure</h2>
					<p className={s.mainText}>Security is at the heart of the product. The site uses the advanced login technology of Auth0 to protect the pages and the payments are processed using <a href="https://stripe.com/customers" target="_blank" rel="noopener noreferrer">Stripe</a>. <a href="https://stripe.com/customers" target="_blank" rel="noopener noreferrer">Stripe</a> is used by leading companies like Facebook, Unicef, Oxfam and a million more.</p>
				</div>
				<div>
					<i className={`fas fa-lock ${s.mainIcon} ${s.mainIcon2} ${s.mainIconRight}`}></i>
				</div>
			</section>
			<section className={s.mainSection}>
				<div>
					<i className={`fas fa-dollar-sign ${s.mainIcon} ${s.mainIcon3}`}></i>
				</div>
				<div>
					<h2 className={s.mainTitle}>Affordable</h2>
					<p className={s.mainText}>We do not charge extra fees, only the normal credit card fees because the money of your campaign should go towards your goal.</p>
				</div>
			</section>

			<div className={s.mainButton} style={{ textAlign: 'center' }}>
				<a href="https://fundmycampaign.typeform.com/to/ab7Bhl" target="_blank" rel="noopener noreferrer">
					<Button green auto>Create a new campaign</Button>
				</a>
			</div>

		</div>
	</main>
</Layout>
